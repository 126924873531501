import React, { useEffect, useState } from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Slider from "react-slick"
import OnClickButtonDynamic from "../Buttons/OnClickButtonDynamic"
import ButtonGrey from "../Buttons/ButtonGrey"
import ContentContainer from "../Container/ContentContainer"
import BlockContainer from "../Container/BlockContainer"
import ArticleSlider from "./ArticleSlider"
import ArticleList from "./ArticleList"
import { isBrowser } from "../../../services/browser"

const Background = styled.div`
  padding: 74px 0;
  transition: all 0.5s;

  @media (min-width: ${breakpoints.md}) {
    padding: ${(props) => (props.limited ? "105px 0 108px" : "100px 0 108px")};
  }
`

const IntroSection = styled.div`
  text-align: center;
  margin-bottom: 81px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 739px;
    margin: 0 auto 51px;
  }
`

const IntroTitle = styled.h2`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
  }
`

const Intro = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 160%;
  margin-top: 13px;

  p {
    margin-bottom: 18px;
    b {
      ${fonts.labGrotesqueBold};
    }
    i {
      font-style: italic;
    }
    a {
      color: ${colors.grey};
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const ButtonContainer = styled.div`
  padding: 0 9px;

  button {
    width: 100%;
    min-width: 230px;
    padding-left: 10px;
    padding-right: 10px;
    background: ${(props) => props.active && "transparent"};
    border-color: ${(props) => props.active && colors.grey};
    color: ${(props) => props.active && colors.grey};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0 10px;
    button {
      width: 246px;
    }
  }
`

const StyledSlider = styled(Slider)`
  margin: auto;
  margin-bottom: 40px;
  max-width: ${(props) => props.slidesCount * 266}px;

  .slick-track {
    display: flex;
  }

  @media (min-width: ${breakpoints.md}) {
    .slick-list {
      padding: 0px !important;
    }
  }
`

const ReadAll = styled.div`
  width: 100%;
  margin-top: 43px;

  a {
    width: 100%;
    max-width: 317px;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 96px;
    a {
      max-width: 249px;
    }
  }
`

const ComingSoon = styled.div`
  ${fonts.canelaThin};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 1.2;
  margin: 96px auto;
  @media (min-width: ${breakpoints.md}) {
    margin: 96px auto 0;
  }
`

const FilterCarousel = (props) => {
  const { header, subHeader, filters, ctaLabel, ctaLink, slides, limited } =
    props
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [width, setWidth] = useState(0)

  const handleFilterChange = (category) => {
    if (categoryFilter === category) {
      setCategoryFilter(null)
    } else {
      setCategoryFilter(category)
    }
  }

  const settings = {
    infinite: true,
    initialSlide: 0,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    variableHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "70px",
        },
      },
    ],
  }

  const getWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  const filterByCategory = (articles) => {
    if (!categoryFilter) {
      return articles
    } else {
      let filtered = []

      articles.forEach((article) => {
        if (article.category.slug === categoryFilter) {
          filtered.push(article)
        }
      })

      return filtered
    }
  }

  let filteredArticles = filterByCategory(slides)

  useEffect(() => {
    if (isBrowser()) {
      getWindowWidth()
      window.addEventListener("resize", getWindowWidth)
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth)
    }
  })

  return (
    <Background limited={limited ? 1 : 0}>
      <ContentContainer>
        <IntroSection>
          <IntroTitle>{header}</IntroTitle>
          {subHeader && (
            <Intro>{renderRichText(subHeader)}</Intro>
          )}
        </IntroSection>
      </ContentContainer>
      {filters && (
        <BlockContainer>
          <StyledSlider {...settings} slidesCount={filters.length}>
            {filters.map((filter, index) => {
                const { slug, categoryColor, title } = filter
                return (
                  <ButtonContainer key={index} active={categoryFilter === slug}>
                    <OnClickButtonDynamic
                      transparent
                      onClick={() => handleFilterChange(slug)}
                      color={categoryColor}
                    >
                      {title}
                    </OnClickButtonDynamic>
                  </ButtonContainer>
                )

            })}
          </StyledSlider>
        </BlockContainer>
      )}
      {!limited || width <= 992 ? (
        filteredArticles && filteredArticles.length > 0 ? (
          <ArticleSlider articles={filteredArticles} />
        ) : (
          <ComingSoon>Coming soon...</ComingSoon>
        )
      ) : (
        <ArticleList articles={filteredArticles.slice(0, 3)} />
      )}
      {ctaLabel && (
        <ReadAll>
          <ContentContainer>
            <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
          </ContentContainer>
        </ReadAll>
      )}
    </Background>
  )
}

export default FilterCarousel
