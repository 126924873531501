import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql, useStaticQuery } from "gatsby"
import ContentContainer from "./Container/ContentContainer"
import Star from "../../resources/img/ui/star.svg"
import ButtonGrey from "./Buttons/ButtonGrey"
import { Col, Container, Row } from "styled-bootstrap-grid"
import { getLocalisedProductPrice } from "../../services/product/price"
import { getProductLink } from "../../services/product/link"
import BrandPattern from "../../resources/img/patterns/brand-pattern-01.svg"

const Background = styled.div`
  margin: 48px 0 56px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin: 74px 0 78px;
  }
`

const Intro = styled.div`
  ${fonts.canelaThin};
  font-size: 72px;
  line-height: 72px;
  max-width: 754px;
  margin: auto;
  text-align: center;
  margin-bottom: 34px;
  position: relative;
  z-index: 1;

  i {
    ${fonts.canelaThinItalic};
  }

  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    margin-bottom: 64px;
  }
`

const Product = styled.div``

const ProductImageContainer = styled.div`
  padding: 0 45px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0 90px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    max-width: 512px;
  }
`

const ProductInfoContainer = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 45px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-right: 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
  }
`

const ProductInfo = styled.div`
  margin-top: 54px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`

const ProductName = styled.div`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 130%;
  margin-bottom: 11px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 10px;
  }
`

const Ratings = styled.div`
  margin-bottom: 18px;
  img {
    margin-right: 9px;
    :last-of-type {
      margin-right: 0;
    }
  }
`

const Reviews = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  display: inline-block;
  margin-left: 13px;
  font-size: 18px;
`

const Price = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 19px;
  letter-spacing: 3.8px;
`

const ProductDescription = styled.div`
  margin: 34px auto 45px;
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 180%;

  strong {
    ${fonts.labGrotesqueBold};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 24px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  a {
    min-width: 100%;
  }
  @media (min-width: ${breakpoints.md}) {
    a {
      min-width: 215px;
    }
  }
`

const StyledContainer = styled(Container)`
  max-width: ${breakpoints.xl};
`

const ProductVideo = styled.div`
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    width: 150%;
    height: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Pattern = styled.div`
  position: absolute;
  pointer-events: none;
  left: -40px;
  top: ${(props) => (props.home ? "-100%" : "50%")};
  transform: ${(props) => (props.home ? "rotate(43deg)" : "translateY(-50%)")};
  img {
    max-width: 300px;
  }
  opacity: 0.6;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    left: -120px;
    top: ${(props) => (props.home ? "-50%" : "50%")};
    display: block;
  }
  @media (min-width: ${breakpoints.lg}) {
    img {
      max-width: 350px;
    }
  }
  @media (min-width: ${breakpoints.xxl}) {
    left: -120px;
    img {
      max-width: 400px;
    }
  }
`

const ProductHighlight = (props) => {
  const {
    introContent,
    ctaLabel,
    home,
    product: { productSlug },
  } = props
  const {
    products: { nodes: products },
    productContents: { nodes: productContents },
    reviews: { nodes: reviews },
  } = useStaticQuery(
    graphql`
      query {
        products: allShopifyProduct {
          nodes {
            images {
              gatsbyImageData(width: 1024)
            }
            description
            price: priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            name: title
            slug: handle
          }
        }
        productContents: allContentfulProductContent {
          nodes {
            productSlug
            productMedias {
              file {
                url
                contentType
              }
              gatsbyImageData(width: 626, aspectRatio: 1)
            }
            hoverImage {
              file {
                contentType
                url
              }
              gatsbyImageData(width: 626, aspectRatio: 1)
            }
          }
        }
        reviews: allContentfulProductReview {
          nodes {
            rating
            productSlug
          }
        }
      }
    `
  )

  const productReviews = reviews.filter(
    (review) => review.productSlug === productSlug
  )

  let totalRating = 0
  let rating = 0
  let stars = []
  let reviewsLength = productReviews.length

  productReviews.forEach((review) => {
    totalRating = totalRating + review.rating
    rating = Math.round(totalRating / reviewsLength)
  })

  for (let i = 1; i <= rating; i++) {
    stars.push(<img src={Star} alt="star" key={i} />)
  }

  let product
  let content

  if (products) {
    product = products.find((product) => product.slug === productSlug)
  }

  if (product) {
    content = productContents.find(
      (content) => content.productSlug === product.slug
    )
  }

  return (
    <Background>
      <ContentContainer>
        {introContent && (
          <Intro>{renderRichText(introContent)}</Intro>
        )}
        <Product>
          <StyledContainer>
            {product && (
              <Row>
                <Col md={6} xl={6} alignSelf={"center"}>
                  <ProductImageContainer>
                    {content ? (
                      content.productMedias &&
                      (content.productMedias[0].file.contentType.includes(
                        "video"
                      ) ? (
                        <ProductVideo>
                          <video
                            src={content.productMedias[0].file.url}
                            autoPlay
                            muted
                            loop
                            playsInline
                            preload="metadata"
                            aria-label={product.title}
                          />
                        </ProductVideo>
                      ) : (
                        <GatsbyImage
                          image={content.productMedias[0].gatsbyImageData}
                          alt={product.title}
                        />
                      ))
                    ) : (
                      <GatsbyImage
                        image={
                          product.images[0].fileLocal.childImageSharp.gatsbyImageData
                        }
                        alt={product.title}
                      />
                    )}
                  </ProductImageContainer>
                </Col>
                <Col md={6} xl={5} xlOffset={1} alignSelf={"center"}>
                  <ProductInfoContainer>
                    <ProductInfo>
                      <ProductName>{product.name}</ProductName>
                      <Ratings>
                        {stars}
                        <Reviews>({reviewsLength}) Reviews</Reviews>
                      </Ratings>
                      <Price>
                        {getLocalisedProductPrice(product)}
                      </Price>
                      <ProductDescription
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    </ProductInfo>
                    {ctaLabel && (
                      <ButtonContainer>
                        <ButtonGrey to={getProductLink(product.slug)}>
                          {ctaLabel}
                        </ButtonGrey>
                      </ButtonContainer>
                    )}
                  </ProductInfoContainer>
                </Col>
              </Row>
            )}
          </StyledContainer>
        </Product>
      </ContentContainer>
      <Pattern home={home ? 1 : 0}>
        <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
      </Pattern>
    </Background>
  )
}

export default ProductHighlight
