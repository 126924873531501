import React, { useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { GatsbyImage } from "gatsby-plugin-image"
import Star from "../../../resources/img/ui/star.svg"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../../styles/breakpoints"

const CardContainer = styled.div`
  padding: 0 30px;
  @media (min-width: ${breakpoints.md}) {
    margin: 0 38px;
    padding: 0;
    height: 100%;
    transition: margin 0.5s;
    display: flex;
    flex-wrap: wrap;
    width: 358px !important;
  }
`

const ImageBox = styled.div`
  height: 514px;
  @media (min-width: ${breakpoints.sm}) {
    height: 600px;
  }
  @media (min-width: ${breakpoints.md}) {
    min-height: unset;
    height: 578px;
    display: flex;
    align-items: flex-end;
  }
`

const ImageContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  height: 100%;
  img {
    height: 100% !important;
    width: unset;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
    margin: auto;
    margin-bottom: 0;
    height: ${(props) => (props.active ? "578px" : "515px")};
    width: ${(props) => (props.active ? "357px" : "328px")};
    transition: all 0.5s;
  }
`

const VoiceButton = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: ${(props) => (props.play ? colors.grey : colors.clay)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 21px;
  right: 18px;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    background: ${colors.grey};
  }

  span {
    ${fonts.canelaThinItalic};
    color: ${colors.white};
    font-size: 24px;
    line-height: 120%;
  }
`

const ClientDetails = styled.div`
  margin-top: 35px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 15px;
  }
`

const NameRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`

const Name = styled.h3`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 140%;
`

const Location = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 140%;
`

const Stars = styled.div`
  display: flex;
  img {
    margin-right: 7px;
    width: 12px;
    :last-of-type {
      margin-right: 0;
    }
  }
`

const ReviewContent = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 150%;
  margin-top: 43px;

  i {
    font-style: italic;
  }

  a {
    color: ${colors.grey};
  }
`

const options = {
  renderText: (text) => {
    return ["“", text, "”"]
  },
}

const ReviewCard = (props) => {
  const [play, setPlay] = useState(false)
  const { clientName, rating, review, image, location, active, sound } = props
  let audioRef = useRef(null)

  let stars = []

  for (let i = 0; i <= rating; i++) {
    stars.push(<img src={Star} alt="Star" key={i} />)
  }

  const handleClick = () => {
    if (play) {
      setPlay(false)
      audioRef.pause()
    } else {
      setPlay(true)
      audioRef.play()
    }
  }

  return (
    <CardContainer active={active}>
      <div>
        <ImageBox>
          <ImageContainer active={active}>
            {image && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={clientName}
                style={{ height: "100%", width: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            )}
            {sound && (
              <VoiceButton onClick={() => handleClick()} play={play ? 1 : 0}>
                <span>{play ? "off" : "on"}</span>
                <audio
                  src={sound.file.url}
                  typeof={sound.contentType}
                  ref={(ref) => (audioRef = ref)}
                >
                  <track kind="captions" />
                </audio>
              </VoiceButton>
            )}
          </ImageContainer>
        </ImageBox>
        <ClientDetails>
          <NameRow>
            <div>
              <Name>{clientName}</Name>
              {location && <Location>{location}</Location>}
            </div>
            <Stars>{stars}</Stars>
          </NameRow>
          {review && (
            <ReviewContent>{renderRichText(review, options)}</ReviewContent>
          )}
        </ClientDetails>
      </div>
    </CardContainer>
  )
}

export default ReviewCard
