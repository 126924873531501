import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import Slider from "react-slick"
import ContentContainer from "./Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const Background = styled.div`
  margin: 158px auto 139px;

  @media (min-width: ${breakpoints.md}) {
    margin: 79px auto 219px;
  }
`

const PressLogo = styled.div`
  padding: 0 23px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  transition: all 0.5s;
  text-align: center;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  img {
    margin: auto;
    width: 120px;
    object-fit: contain;
    min-height: 21px;
    max-height: 50px;
  }
`

const Clippings = styled.div`
  margin-bottom: 98px;
`

const Quote = styled.div`
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 1s;
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  i {
    ${fonts.canelaThinItalic};
  }

  a {
    color: ${colors.grey};
  }

  @media (min-width: ${breakpoints.md}) {
    line-height: 130%;
  }
`

const StyledSlider = styled(Slider)`
  margin: 0 auto;
  max-width: ${props => props.width ? props.width : 840}px;

  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    outline: none;
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items:  center;
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <>“{children}”</>,
  },
}

const Press = (props) => {
  const { pressList } = props
  const [slideIndex, setSlideIndex] = useState(0)
  const [hover, setHover] = useState(false)
  let clippingRef = useRef(null)
  let logoRef = useRef(null)

  const changeSlide = (index) => {
    clippingRef.slickGoTo(index)
    logoRef.slickGoTo(index)
  }

  useEffect(() => {
    if (pressList.length > 1) {
      changeSlide(slideIndex)
    }
  }, [slideIndex])

  const settings = {
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: "0",
    arrows: false,
    initialSlide: 0,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  const logoSliderSettings = {
    variableWidth: true,
    centerPadding: "50px",
  }

  let pressContainerWidth = 840;

  if (pressList.length <= 6) {
    // if they're even number add width for one more to render odd number to center the active logo
    pressContainerWidth = pressList.length % 2 === 0 ? (pressList.length + 1) * 170 : pressList.length * 170
  }

  return (
    <Background
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Clippings>
        <ContentContainer>
          <StyledSlider ref={(ref) => (clippingRef = ref)} {...settings}>
            {pressList.map((press, index) => {
              const { content } = press
              return (
                <Quote key={index} active={index === slideIndex}>
                  {renderRichText(content, options)}
                </Quote>
              )
            })}
          </StyledSlider>
        </ContentContainer>
      </Clippings>

      {pressList.length > 1 ? (
        <StyledSlider
          {...settings}
          {...logoSliderSettings}
          ref={(ref) => (logoRef = ref)}
          width={pressContainerWidth}
        >
          {pressList.map((press, index) => {
            const { logo, author } = press
            return (
              <PressLogo
                active={slideIndex === index}
                key={index}
                onClick={() => changeSlide(index)}
              >
                <img src={logo.file.url} alt={author} />
              </PressLogo>
            )
          })}
        </StyledSlider>
      ) : (
        <PressLogo active={1}>
          <img src={pressList[0].logo.file.url} alt={pressList[0].author} />
        </PressLogo>
      )}
    </Background>
  )
}

export default Press
