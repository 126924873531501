import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row } from "styled-bootstrap-grid"
import ArrowButton from "../Buttons/ArrowButton"
import ContentContainer from "../Container/ContentContainer"
import { getArticleLink } from "../../../services/blog/link"

const StyledRow = styled(Row)`
  width: 100%;
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 101px;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding: 0 10px;
`

const ArticleCard = styled.div`
  width: 318px;
  height: inherit;
`

const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 47px;
  border-radius: 15px;
  overflow: hidden;
`

const ArticleContent = styled.div`
  padding: 0 30px;
  text-align: center;
  box-sizing: border-box;
`

const Category = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  span {
    border-bottom: 1px solid ${props => props.color};
  }
`

const ArticleTitle = styled.div`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 24px;
  line-height: 120%;
  margin-top: 26px;
  margin-bottom: 18px;
`

const ArticleList = (props) => {
  const { articles } = props
  return (
    <ContentContainer>
      <StyledRow>
        {articles.map((article, index) => {
          const { thumbnail, title, category, slug } = article
          let width = 12 / articles.length
          return (
            <StyledCol key={index} md={width}>
              <ArticleCard>
                <ImageContainer>
                  <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
                </ImageContainer>
                <ArticleContent>
                  <Category color={category.categoryColor}>
                    <span>{category.title}</span>
                  </Category>
                  <ArticleTitle>{title}</ArticleTitle>
                  <ArrowButton to={getArticleLink(slug)}>Read More</ArrowButton>
                </ArticleContent>
              </ArticleCard>
            </StyledCol>
          )
        })}
      </StyledRow>
    </ContentContainer>
  )
}

export default ArticleList
