import React from "react"
import styled from "styled-components"
import { ButtonStyleDynamic } from "./Styles"
import PropTypes from "prop-types"

const ButtonContainer = styled.button`
  ${ButtonStyleDynamic}
`;

const OnClickButtonDynamic = ({onClick, children, transparent, color, disabled, type}) => {
  return (
    <ButtonContainer onClick={onClick} transparent={transparent ? 1 : 0} color={color} disabled={disabled ? 1 : 0} type={type}>
      {children}
    </ButtonContainer>
  )
};

OnClickButtonDynamic.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default OnClickButtonDynamic;
