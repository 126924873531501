import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import ButtonGrey from "../Core/Buttons/ButtonGrey"

const HeroContainer = styled.div`
  margin: auto;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`

const HeroImage = styled(GatsbyImage)`
  height: 565px;

  @media (min-width: ${breakpoints.md}) {
    height: 843px;
  }
`

const Mobile = styled.div`
  display: block;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`
const Desktop = styled.div`
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: block;
    max-height: 843px;
    overflow: hidden;
  }
`

const HeroContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`

const HeroContent = styled.div`
  padding: 54px 0 50px;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    padding: 25px 0 0;
  }
`

const Header = styled.h1`
  ${fonts.canelaThin};
  color: ${colors.white};
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    font-size: 70px;
    max-width: 894px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    min-width: 317px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 23px;
    a {
      min-width: 215px;
    }
  }
`
const HeroVideo = styled.video`
  width: 100%;
  height: 100%;
`

const Hero = (props) => {
  const { hero, mobileHero, header, ctaLabel, ctaLink } = props
  const mobile = mobileHero ? mobileHero : hero
  return (
    <HeroContainer>
      <Mobile>
        {mobile.file.contentType.includes("video") ? (
          <HeroVideo
            src={mobile.file.url}
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
            aria-label="video"
          />
        ) : (
          <HeroImage image={mobile.gatsbyImageData} alt={header} />
        )}
      </Mobile>
      <Desktop>
        {hero.file.contentType.includes("video") ? (
          <HeroVideo
            src={hero.file.url}
            autoPlay
            muted
            loop
            playsInline
            aria-label="video"
          />
        ) : (
          <HeroImage image={hero.gatsbyImageData} alt={header} />
        )}
      </Desktop>
      <HeroContentContainer>
        <ContentContainer>
          <HeroContent>
            <Header>{header}</Header>
            {ctaLink && ctaLabel && (
              <ButtonContainer>
                <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
              </ButtonContainer>
            )}
          </HeroContent>
        </ContentContainer>
      </HeroContentContainer>
    </HeroContainer>
  )
}

export default Hero
