import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContent from "../components/Home/PageContent"

const IndexPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data

  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
      />
      <PageContent blocks={page.blocks} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    page: contentfulPage(slug: { eq: "homepage" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          id
          header
          ctaLabel
          ctaLink
          hero {
            file {
              url
              contentType
            }
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(height: 1130)
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ...uspBlockFragment
        ...fullWidthCtaBlockFragment
        ... on ContentfulArticleCarouselBlock {
          id
          header
          subHeader {
            raw
          }
          filters {
            ... on ContentfulCategory {
              title
              slug
              categoryColor
            }
          }
          slides {
            __typename
            ... on ContentfulArticle {
              category {
                title
                slug
                categoryColor
              }
              slug
              title
              thumbnail {
                gatsbyImageData(width: 636, aspectRatio: 1)
              }
            }
          }
          ctaLabel
          ctaLink
        }
        ...carouselBlockFragment
        ... on ContentfulPress {
          id
          logo {
            file {
              url
            }
          }
          author
          content {
            raw
          }
        }
        ... on ContentfulInstagram {
          id
          name
          image {
            gatsbyImageData(width: 636, aspectRatio: 1)
          }
          username
          link
          profileImage {
            gatsbyImageData(width: 102)
          }
        }
      }
    }
  }
`
