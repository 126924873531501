import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import ArrowButton from "../Buttons/ArrowButton"
import { getArticleLink } from "../../../services/blog/link"

const SliderContainer = styled.div`
  margin: 40px auto 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 80px auto 0;
  }
`

const Slide = styled.div`
  display: flex;
  justify-content: center;
  max-width: calc(100vw - 128px);
  outline: none !important;

  @media (min-width: ${breakpoints.md}) {
    max-width: 318px;
    margin: 0 44px;
  }
`

const ImageContainer = styled.div`
  width: calc(100vw - 150px);
  margin: 0 11px 43px;
  border-radius: 15px;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 47px;
  }
`

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }
`

const ArticleContent = styled.div`
  padding: 0 30px;
  text-align: center;
  width: 100vw;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease-in;
  margin-left: -64px;

  @media (min-width: ${breakpoints.md}) {
    display: block;
    margin-left: unset;
    opacity: 1;
    padding: 0;
    width: auto;
  }
`

const Category = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  span {
    border-bottom: 1px solid ${(props) => props.color};
  }
`

const ArticleTitle = styled.div`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 24px;
  line-height: 120%;
  margin-top: 26px;
  margin-bottom: 18px;
`

const ArticleSlider = (props) => {
  const { articles } = props
  const [slideIndex, setSlideIndex] = useState(0)

  let imageRef = useRef(null)

  const changeSlide = (index) => {
    imageRef.slickGoTo(index)
  }

  useEffect(() => {
    changeSlide(slideIndex)
  }, [slideIndex])

  const settings = {
    infinite: true,
    initialSlide: 0,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    centerPadding: "75px",
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <SliderContainer>
      <StyledSlider {...settings} ref={(ref) => (imageRef = ref)}>
        {articles.map((article, index) => {
          const { thumbnail, title, category, slug } = article
          return (
            <Slide key={index}>
              <ImageContainer>
                <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
              </ImageContainer>
              <ArticleContent visible={slideIndex === index} desktop placeholder={1}>
                <Category index={index} color={category.categoryColor}>
                  <span>{category.title}</span>
                </Category>
                <ArticleTitle>{title}</ArticleTitle>
                <ArrowButton to={getArticleLink(slug)}>Read More</ArrowButton>
              </ArticleContent>
            </Slide>
          )
        })}
      </StyledSlider>
    </SliderContainer>
  )
}

export default ArticleSlider
