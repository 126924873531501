import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContentContainer from "../Container/ContentContainer"
import { Col, Row } from "styled-bootstrap-grid"
import ButtonGrey from "../Buttons/ButtonGrey"
import Slider from "react-slick"
import ReviewCard from "./ReviewCard"
import CategoryCard from "./CategoryCard"
import BrandPattern from "../../../resources/img/patterns/brand-pattern-01.svg"
import Arrow from "../../../resources/img/ui/dark/chevron-down-dark.svg"

const BlockContainer = styled.div`
  margin: 74px 0 158px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin: 85px 0 139px;
  }
`

const CarouselContainer = styled.div`
  overflow: hidden;
`

const Title = styled.div`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  i {
    ${fonts.canelaThinItalic};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
    max-width: 730px;
    margin: auto;
  }
`

const Intro = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  margin-top: 22px;

  a {
    color: inherit;
  }

  i {
    font-style: italic;
  }
  @media (min-width: ${breakpoints.md}) {
    max-width: 1000px;
    margin: 13px auto 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  a {
    min-width: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    a {
      min-width: 249px;
    }
  }
`

const StyledSlider = styled(Slider)`
  margin-top: 74px;
  margin-bottom: 35px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 64px;
    .slick-list {
      overflow: visible;
    }
  }
`

const IndicatorContainer = styled.div`
  padding: 0 30px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const SlideIndicator = styled.div`
  width: 100%;
  background: rgba(88, 89, 91, 0.2);
  position: relative;
  display: flex;
`

const IndicatorLine = styled.div`
  height: 1px;
  width: ${(props) => `calc(100% / ${props.slidesCount})`};
`

const changeLineColor = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`

const ActiveLine = styled.div`
  height: 100%;
  background-color: ${colors.grey};
  width: ${(props) => (props.passed ? "100%" : "0%")};
  animation: ${(props) => props.active && changeLineColor} 4s linear;
`

const Pattern = styled.div`
  position: absolute;
  pointer-events: none;
  top: -35%;
  left: -120px;
  opacity: 0.6;
  z-index: -1;
  display: none;
  img {
    max-width: 854px;
  }
  @media (min-width: ${breakpoints.md}) {
    display: ${(props) => (props.visible ? "block" : "none")};
  }
`

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: -20px;
  height: auto;
  width: 100%;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
  }
`

const SlideArrow = styled.div`
  cursor: pointer;
  transform: ${(props) => (props.prev ? "rotate(90deg)" : "rotate(-90deg)")};
  
  img {
    height: 10px;
    width: auto;
    display: block;
    margin: ${(props) => (props.prev ? "0 15px 0 0" : "0 0 0 15px")};
  }
`

const Carousel = (props) => {
  const [slideIndex, setSlideIndex] = useState(0)

  let sliderRef = useRef(null)

  const changeSlide = (slideIndex) => {
    sliderRef.slickGoTo(slideIndex)
    setSlideIndex(slideIndex)
  }

  const next = () => {
    sliderRef.slickNext()
  }

  const previous = () => {
    sliderRef.slickPrev()
  }

  const {
    carouselHeader,
    subHeader,
    slides,
    ctaLabel,
    ctaLink,
    ctaPosition,
    blog,
    pattern,
  } = props

  const settings = {
    autoplay: blog ? false : true,
    speed: 1000,
    autoplaySpeed: 4000,
    initialSlide: 0,
    centerMode: true,
    variableWidth: true,
    adaptiveHeight: false,
    arrows: false,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          centerMode: false,
          autoplay: true,
        },
      },
    ],
  }

  const renderSlide = (slide, index) => {
    const { __typename: type } = slide

    switch (type) {
      case "ContentfulReview":
        return <ReviewCard {...slide} active={slideIndex === index} />
      case "ContentfulCategory":
        return <CategoryCard {...slide} active={slideIndex === index} />
      default:
        return null
    }
  }

  return (
    <BlockContainer>
      <CarouselContainer>
        <Row>
          <Col>
            <ContentContainer>
              {carouselHeader && (
                <Title>{renderRichText(carouselHeader)}</Title>
              )}
              {subHeader && (
                <Intro>{renderRichText(subHeader)}</Intro>
              )}
            </ContentContainer>
          </Col>
          {ctaLabel && ctaLink && (
            <Col order={ctaPosition ? "auto" : "last"}>
              <ContentContainer>
                <ButtonContainer>
                  <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
                </ButtonContainer>
              </ContentContainer>
            </Col>
          )}
          <Col>
            <StyledSlider {...settings} ref={(ref) => (sliderRef = ref)}>
              {slides.map((slide, index) => {
                return (
                  <React.Fragment key={index}>
                    {renderSlide(slide, index)}
                  </React.Fragment>
                )
              })}
            </StyledSlider>
            <IndicatorContainer>
              <SlideIndicator>
                {slides.map((slide, index) => {
                  return (
                    <IndicatorLine
                      key={index}
                      slidesCount={slides.length}
                      onClick={() => changeSlide(index)}
                    >
                      <ActiveLine
                        active={slideIndex === index}
                        passed={slideIndex >= index}
                      />
                    </IndicatorLine>
                  )
                })}
              </SlideIndicator>
            </IndicatorContainer>
          </Col>
          <ButtonsContainer>
            <SlideArrow onClick={previous} prev>
              <img src={Arrow} alt="previous"></img>
            </SlideArrow>
            <SlideArrow onClick={next}>
              <img src={Arrow} alt="next"></img>
            </SlideArrow>
          </ButtonsContainer>
        </Row>
      </CarouselContainer>
      <Pattern visible={pattern ? 1 : 0}>
        <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
      </Pattern>
    </BlockContainer>
  )
}

export default Carousel
