import React from "react"
import Hero from "./Hero"
import ProductHighlight from "../Core/ProductHighlight"
import UspBlock from "../Core/USP/UspBlock"
import QuizCtaBlock from "../Core/FullWidthCta/FullWidthCtaBlock"
import Press from "../Core/Press"
import BlogCarousel from "../Core/BlogCarousel/BlogCarousel"
import Carousel from "../Core/Carousel/Carousel"
import Instagram from "./Instagram"
import styled from "styled-components"

const HomepageContainer = styled.div`
  overflow: hidden;
`

const PageContent = ({ blocks }) => {
  let pageContents = []
  let press = []
  let instagram = []

  blocks.forEach((block, index) => {
    const type = block.__typename

    if (type === "ContentfulHeroBlock") {
      pageContents.push(<Hero {...block} key={index} />)
    }
    if (type === "ContentfulProductHighlightBlock") {
      pageContents.push(<ProductHighlight {...block} key={index} home/>)
    }
    if (type === "ContentfulUspBlock") {
      pageContents.push(<UspBlock {...block} key={index} />)
    }
    if (type === "ContentfulFullWidthCtaBlock") {
      pageContents.push(<QuizCtaBlock {...block} key={index} />)
    }
    if (type === "ContentfulArticleCarouselBlock") {
      pageContents.push(<BlogCarousel {...block} key={index} />)
    }
    if (type === "ContentfulCarouselBlock") {
      pageContents.push(<Carousel {...block} key={index} />)
    }
    if (type === "ContentfulPress") {
      press.push(block)
    }
    if (press.length !== 0 && index === blocks.map(el => el.__typename).lastIndexOf("ContentfulPress")) {
      pageContents.push(<Press pressList={press} key={index}/>)
    }
    if (type === "ContentfulInstagram") {
      instagram.push(block)
    }
    if (instagram.length !== 0 && index === blocks.map(el => el.__typename).lastIndexOf("ContentfulInstagram")) {
      pageContents.push(<Instagram posts={instagram} key={index}/>)
    }
  })

  return <HomepageContainer>{pageContents}</HomepageContainer>
}

export default PageContent
