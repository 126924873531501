import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import Slider from "react-slick"
import BrandPattern from "../../resources/img/patterns/brand-pattern-02.svg"
import Arrow from "../../resources/img/ui/dark/chevron-down-dark.svg"

const StyledContainer = styled.div`
  margin: 139px auto 77px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin: 219px auto 114px;
  }
`

const TitleSection = styled.div`
  margin-bottom: 66px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 86px;
  }
`

const Title = styled.h2`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  a {
    color: inherit;
    text-decoration: none;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
  }
`

const Mobile = styled.div`
  display: inline-block;
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`

const Slide = styled.div`
  padding: 0 11px;
`

const InstagramContainer = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  overflow: hidden;
  a {
    cursor: pointer;
    text-decoration: none;
  }
`

const ImageContainer = styled.div`
  width: calc(100vw - 130px);
  height: calc(100vw - 130px);

  @media (min-width: ${breakpoints.md}) {
    width: 318px;
    height: 318px;
  }
`

const UserInfo = styled.div`
  border-top: 1px solid ${colors.grey};
  background: ${colors.peach};
  padding: 10px 13px;
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.md}) {
    padding: 14px 19px;
  }
`

const UserProfile = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  @media (min-width: ${breakpoints.md}) {
    width: 51px;
    height: 51px;
  }
`

const Name = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 12px;
  line-height: 110%;
  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 140%;
  }
`

const Username = styled(Name)`
  ${fonts.labGrotesqueLight};
`

const Pattern = styled.div`
  position: absolute;
  bottom: -97px;
  z-index: -1;
  pointer-events: none;
  left: -40px;
  transform: scaleX(-1);
  img {
    max-width: 367px;
  }
  opacity: 0.6;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
    left: unset;
    right: 0;
    bottom: -150px;
    transform: scaleX(1);

    img {
      max-width: 704px;
    }
  }
`
const ButtonsContainer = styled.div`
  position: absolute;
  bottom: -60px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`

const SlideArrow = styled.div`
  cursor: pointer;
  transform: ${(props) => (props.prev ? "rotate(90deg)" : "rotate(-90deg)")};
  
  img {
    height: 10px;
    width: auto;
    display: block;
    margin: ${(props) => (props.prev ? "0 15px 0 0" : "0 0 0 15px")};
  }
`

const Instagram = (props) => {
  const { posts } = props

  const settings = {
    infinite: true,
    initialSlide: 0,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  }

  let sliderRef = useRef(null)

  const next = () => {
    sliderRef.slickNext()
  }

  const previous = () => {
    sliderRef.slickPrev()
  }

  return (
    <StyledContainer>
      <TitleSection>
        <ContentContainer>
          <Title>
            Join our circle{" "}
            <Mobile>
              <br />
            </Mobile>
            <a
              href="https://www.instagram.com/ourhummingway/"
              rel="noreferrer noopener"
              target="_blank"
            >
              @ourhummingway
            </a>
          </Title>
        </ContentContainer>
      </TitleSection>
      <Slider {...settings} ref={(ref) => (sliderRef = ref)}>
        {posts.map((post, index) => {
          const { image, name, username, profileImage } = post
          return (
            <Slide key={index}>
              <InstagramContainer>
                <a
                  href="https://www.instagram.com/ourhummingway/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <ImageContainer>
                    <GatsbyImage image={image.gatsbyImageData} alt={username} />
                  </ImageContainer>
                  <UserInfo>
                    <UserProfile>
                      <GatsbyImage image={profileImage.gatsbyImageData} alt={username} />
                    </UserProfile>
                    <div>
                      <Name>{name}</Name>
                      <Username>{username}</Username>
                    </div>
                  </UserInfo>
                </a>
              </InstagramContainer>
            </Slide>
          )
        })}
      </Slider>
      <ButtonsContainer>
            <SlideArrow onClick={previous} prev>
              <img src={Arrow} alt="previous"></img>
            </SlideArrow>
            <SlideArrow onClick={next}>
              <img src={Arrow} alt="next"></img>
            </SlideArrow>
          </ButtonsContainer>
      <Pattern>
        <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
      </Pattern>
    </StyledContainer>
  )
}

export default Instagram
