import React from "react"
import styled, { keyframes } from "styled-components"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"
import BlockContainer from "../Container/BlockContainer"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ButtonGrey from "../Buttons/ButtonGrey"

const BackgroundImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const BackgroundContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const MobileBackgroundImage = styled(BackgroundImage)`
  display: block;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const BlockContents = styled.div`
  max-width: 317px;
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    max-width: 506px;
  }
`

const BlockContentsContainer = styled.div`
  padding: 186px 29px 194px;
  text-align: center;
  position: relative;
  @media (min-width: ${breakpoints.md}) {
    padding: 184px 42px 158px;
  }
`

const Title = styled.h2`
  ${fonts.canelaThin};
  color: ${colors.white};
  font-size: 40px;
  line-height: 100%;

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
  }
`

const Content = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.white};
  font-size: 18px;
  line-height: 160%;
  margin-top: 26px;

  p {
    margin-bottom: 18px;

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }

    a {
      color: ${colors.hummingwayWhite};
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 21px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    min-width: 317px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 51px;
    a {
      min-width: 275px;
    }
  }
`

const CircleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const RotateAnimation = (reverse = false) => keyframes`
  100% {
    transform: ${`rotate(${reverse ? -360 : 360}deg)`}
  }
`

const Circle = styled.div`
  display: ${(props) => (props.bigger ? "none" : "block")};
  position: relative;
  border-radius: 50%;
  width: 600px;
  height: 600px;
  animation: ${(props) => RotateAnimation(props.bigger ? true : false)} 60s
    linear 0s infinite;

  @media (min-width: ${breakpoints.md}) {
    width: 760px;
    height: 760px;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    width: ${(props) => (props.bigger ? "1200px" : "900px")};
    height: ${(props) => (props.bigger ? "1200px" : "900px")};
  }
`

const Letter = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  transform: ${(props) => `rotate(${props.rotate}deg)`};
  ${fonts.labGrotesqueBold};
  color: ${colors.white};
  font-size: 16px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}) {
    font-size: 19px;
  }
`

const QuizCtaBlock = (props) => {
  const {
    background,
    mobileBackground,
    content,
    ctaLabel,
    ctaLink,
    title,
    circularTextSmall,
    circularTextBig,
  } = props

  let smallCircle = []
  let bigCircle = []
  let smArrayIndex = 0
  let bgArrayIndex = 0
  const spaces = "       "

  if (circularTextSmall) {
    for (let i = 0; i <= 12; i++) {
      smallCircle.push(`${circularTextSmall[smArrayIndex]}${spaces}`)
      smArrayIndex =
        smArrayIndex < circularTextSmall.length - 1 ? smArrayIndex + 1 : 0
    }
  }

  if (circularTextBig) {
    for (let i = 0; i <= 16; i++) {
      bigCircle.push(`${circularTextBig[bgArrayIndex]}${spaces}`)
      bgArrayIndex =
        bgArrayIndex < circularTextBig.length - 1 ? bgArrayIndex + 1 : 0
    }
  }

  return (
    <BackgroundContainer>
      <MobileBackgroundImage image={mobileBackground.gatsbyImageData} alt={title} />
      <DesktopBackgroundImage image={background.gatsbyImageData} alt={title} />
      <BlockContainer>
        <BlockContentsContainer>
          <BlockContents>
            <CircleContainer>
              <Circle>
                {smallCircle
                  .join("")
                  .split("")
                  .map((text, index) => {
                    const rotate =
                      index * (360 / smallCircle.join("").split("").length)
                    return (
                      <Letter rotate={rotate} key={index}>
                        {text}
                      </Letter>
                    )
                  })}
              </Circle>
            </CircleContainer>
            <CircleContainer>
              <Circle bigger>
                {bigCircle
                  .join("")
                  .split("")
                  .map((text, index) => {
                    const rotate =
                      index * (360 / bigCircle.join("").split("").length)
                    return (
                      <Letter rotate={rotate} key={index}>
                        {text}
                      </Letter>
                    )
                  })}
              </Circle>
            </CircleContainer>
            <Title>{title}</Title>
            <Content>{renderRichText(content)}</Content>
            {ctaLink && ctaLabel && (
              <ButtonContainer>
                <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
              </ButtonContainer>
            )}
          </BlockContents>
        </BlockContentsContainer>
      </BlockContainer>
    </BackgroundContainer>
  )
}

export default QuizCtaBlock
