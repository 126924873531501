import React from "react"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import styled from "styled-components"
import { Link } from "gatsby"
import Arrow from "../../../resources/img/ui/dark/arrow-right-dark.svg"
import PropTypes from "prop-types"

const ButtonContainer = styled(Link)`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  position: relative;
  padding-right: 48px;

  img {
    width: 31px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

  }
`

const ArrowButton = ({ children, to }) => {
  return (
    <ButtonContainer to={to}>
      <span>{children} </span>
      <img src={Arrow} alt="arrow" />
    </ButtonContainer>
  )
}

ArrowButton.propTypes = {
  to: PropTypes.string.isRequired,
}

export default ArrowButton
