import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { getCategoryLink } from "../../../services/blog/link"

const CardContainer = styled.div`
  padding: 0 30px;
  a {
    color: inherit;
    text-decoration: none;
  }
  @media (min-width: ${breakpoints.md}) {
    margin: 0 38px;
    padding: 0;
    height: 100%;
    transition: margin 0.5s;
    width: min-content;
    display: flex;
    flex-wrap: wrap;
  }
`

const ImageBox = styled.div`
  height: 100vw;

  @media (min-width: ${breakpoints.md}) {
    min-height: unset;
    height: 578px;
    display: flex;
    align-items: flex-end;
  }
`

const ImageContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
    height: ${(props) => (props.active ? "578px" : "515px")};
    width: ${(props) => (props.active ? "357px" : "318px")};
    transition: all 0.5s;
  }
`

const Details = styled.div`
  margin-top: 36px;
  text-align: center;
`

const Category = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  color: ${colors.grey};
  text-decoration: none;
  text-align: center;
  span {
    border-bottom: 2px solid;
    padding-bottom: 1px;
    border-color: ${(props) => props.color};
  }
`

const Description = styled.div`
  ${fonts.labGrotesqueLight};
  margin-top: 26px;
  font-size: 18px;
  line-height: 1.6;
  color: ${colors.grey};
`

const CategoryCard = (props) => {
  const { title, slug, thumbnail, shortDescription, active, categoryColor } =
    props

  return (
    <CardContainer>
      <ImageBox>
        <ImageContainer active={active}>
          <GatsbyImage
            image={thumbnail.gatsbyImageData}
            alt={title}
            style={{ height: "100%", width: "100%" }}
          />
        </ImageContainer>
      </ImageBox>
      <Details>
        <Link to={getCategoryLink(slug)}>
          <Category color={categoryColor}>
            <span>{title}</span>
          </Category>
        </Link>
        <Description
          dangerouslySetInnerHTML={{
            __html: shortDescription.childMarkdownRemark.html,
          }}
        />
      </Details>
    </CardContainer>
  )
}

export default CategoryCard
